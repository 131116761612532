import Vue from 'vue'
import VueRouter from 'vue-router'

import store from './store'

import DashboardPage from './dashboard.vue'
import SigninPage from './signin.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: SigninPage }, 
  { path: '/signin', component: SigninPage },
  { path: '*', component: DashboardPage,
    beforeEnter(to, from, next){
      if(store.state.idToken){
        next()
      } else {
        next('/signin')
      }
    }
  }
]

export default new VueRouter({mode: 'history', routes})