import Vue from 'vue'
import Vuex from 'vuex'
import axios from './axios-auth';
import globalAxios from 'axios';

import router from './router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    idToken: null,
    userId: null,
    user: null,
    dataTable: null
  },
  mutations: {
    authUser(state, userData) {
      state.idToken = userData.token
      state.userId = userData.userId
    },
    storeUser(state, user) {
      state.user = user
    },
    storeData(state, data) {
      state.dataTable = data
    },
    clearAuthData(state) {
      state.idToken = null
      state.userId = null
    }
  },
  actions: {
    setLogoutTimer({ dispatch }, exparationTime) {
      setTimeout(() => {
        dispatch('logout')
      }, exparationTime * 1000)
    },
    login({ commit, dispatch  }, authData) {
      axios.post('/accounts:signInWithPassword?key=AIzaSyBgdxWYyYrSR2pVw8os2Axf1PhythzIHgM', {
        email: authData.email,
        password: authData.password,
        returnSecureToken: true
      })
        .then(response => {
          // console.log(response)
          commit('authUser', {
            token: response.data.idToken,
            userId: response.data.localId
          })
          const now = new Date()
          const expirationDate = new Date(now.getTime() + response.data.expiresIn*1000)
          localStorage.setItem('expirationDate', expirationDate)
          localStorage.setItem('token', response.data.idToken)
          localStorage.setItem('userId', response.data.localId)
          dispatch('setLogoutTimer', response.data.expiresIn)
          dispatch('fetchUser');  // router.push('/dashboard');
        })
        .catch(error => console.log(error))
    },
    storeUser({ state }, userData) {
      if (!state.idToken) return
      globalAxios.post('/users.json' + '?auth=' + state.idToken, userData)
        .then(response => console.log(response))
        .catch(error => console.log(error))
    },
    fetchUser({ commit, state }) {
      if (!state.idToken) return
      //globalAxios.get('/users.json' + '?auth=' + state.idToken)
      globalAxios.get('/table.json' + '?auth=' + state.idToken)
        .then(response => {
          /*
          // console.log(response)
          const users = []
          for (let key in response.data) {
            const user = response.data[key]
            user.id = key
            users.push(user)
          }
          // console.log(users)
          commit('storeUser', users[0])
          */
         commit('storeData', response.data);
         router.push('/dashboard');
        })
        .catch(error => console.log(error))
    },
    logout({ commit }) {
      commit('clearAuthData')
      // localStorage.clear()
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      localStorage.removeItem('expirationDate')
      router.replace('/signin')
    },
    tryAutoLogin({ dispatch, commit }){
      const token = localStorage.getItem('token')
      if(!token) return
      const expirationDate = localStorage.getItem('expirationDate')
      const now = new Date()
      if(now>=expirationDate) return
      const userId = localStorage.getItem('userId')
      commit('authUser', { token, userId })
      dispatch('fetchUser'); // router.push('/dashboard');
    }
  },
  getters: {
    user(state) {
      return state.user
    },
    isAuthenticated(state) {
      return state.idToken !== null
    },
    tableData(state) {
      return state.dataTable
    }
  }
})