import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import router from './router'
import store from './store'

axios.defaults.baseURL = 'https://mxvueserver.firebaseio.com'
// axios.defaults.headers.common['Authorization'] = 'uniqueToken'
axios.defaults.headers.get['Accepts'] = 'application/json'

const reqInterceptor = axios.interceptors.request.use( config => {
  console.log('Request Interceptor', config)
  config.headers.myCustomProp = 'seas' 
  return config
  }
)

const resInterceptor = axios.interceptors.response.use( response => {
  console.log('Response Interceptor', response)  
  return response
  }
)

axios.interceptors.request.eject(reqInterceptor)
axios.interceptors.response.eject(resInterceptor)


Vue.use(ElementUI, { locale })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
