<template>
  <div>
    <div style="margin:5px;">
      <el-button size="mini" type="success" @click="handleAdd">Add</el-button>
      <el-input
      style="float:right; width:25%;"
      v-model="search" size="mini" placeholder="Type to search" />
    </div>
    <el-table  :data="displayData" show-summary :summary-method="getSummaries"
     style="width: 100%" @sort-change="handleSortChange" >
      <el-table-column sortable label="Office" prop="office">
      </el-table-column>
      <el-table-column label="Name" prop="name">
      </el-table-column>
      <el-table-column sortable label="Salary" prop="salary">
      </el-table-column>
      <el-table-column align="right">
        <template slot="header" slot-scope="scope">
          <el-pagination small background layout="prev, pager, next" @current-change="handleCurrentChange"
          :page-size="pageSize" :total="total" >
        </el-pagination>
        </template>
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit" circle/>
          <el-button size="mini" type="danger"  @click="handleDelete(scope.$index, scope.row)" icon="el-icon-delete" circle/>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
    title="Tips"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose">
    <span><p>Your index is {{ curIndex }}</p>
      <p>{{ this.$store.getters.tableData[curIndex].id }}</p>
      <p>{{ this.$store.getters.tableData[curIndex].name }}</p>
      <p>{{ this.$store.getters.tableData[curIndex].office }}</p>
      <p>{{ this.$store.getters.tableData[curIndex].salary }}</p>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="dialogVisible = false">Confirm</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
 // import tableData from './data';
// v-if="$store.getters.tableData" in template el-table
  export default {
    data() {
      return {
        search: '',
        page: 1,
        pageSize: 10,
        dialogVisible: false,
        curIndex: 0
      }
    },
    methods: {
      handleAdd(){
        let max = Math.max.apply(Math, this.$store.getters.tableData.map(function(o) { return o.id; })) + 1;
      // alert('Add item: ' + max);
      this.$store.getters.tableData.push({
                        id:max,
                        name: "Max Muster",
                        office: "Payerbach",
                        salary: 666
                    });

    },
      handleSortChange(event) {
        // bug: now only page is sorted not all records
        console.clear();
        console.log(event.prop, event.order);

      },
/*    @row-click="rowClicked" in el-table
      rowClicked(row){ // funkt aber wenn man was markieren u kopieren will, öffnet sich Dialog
        this.curIndex = this.$store.getters.tableData.map(x => {  // get index in data array
           return x.id;
        }).indexOf(row.id);
        this.dialogVisible = true;
      }, */ 
      handleEdit(index, row) {
        console.log(index, row);
        this.curIndex = this.$store.getters.tableData.map(
          x => x.id ).indexOf(row.id);
        this.dialogVisible = true;
      },
      handleClose(done) {
        this.$confirm('Are you sure to close this dialog?')
          .then(() => {
            done();
          })
          .catch(() => {});
      },
      handleDelete(index, row) { // index referes to displayed items only
        // console.clear();
        // console.log(index, row);
        // console.log(row.id);

        var idx = this.$store.getters.tableData.map(x => {  // get index in data array
          return x.id;
        }).indexOf(row.id);

      this.$store.getters.tableData.splice(idx, 1);

      },
      getSummaries(param) {
        const { columns } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Total/Filtered Salary';
            return;
          }
          else if (index === 1) {
            sums[index] = this.totalAmount.toLocaleString('de-AT', { style: 'currency', currency: 'EUR' });
            return;
          }
          else if (index === 2) {
            sums[index] = this.filteredAmount.toLocaleString('de-AT', { style: 'currency', currency: 'EUR' });
            return;
          }
          else {
            sums[index] = '';
            return;
          }
        });

        return sums;
      },
      handleCurrentChange(val) {
        this.page = val;
      }
    },

    computed: {
      displayData() {

        return this.filtered.slice(this.pageSize * (this.page - 1), this.pageSize * this.page);

      },
      total(){
        let numPages = Math.ceil(this.filtered.length/10);
        let newPage = this.page<=numPages ? this.page : numPages;
        this.handleCurrentChange(newPage); //buggy
        return this.filtered.length;
      },
      filtered(){
        return this.$store.getters.tableData.filter(data =>
          !this.search || data.office.toLowerCase().includes(this.search.toLowerCase())
        );
      },
      totalAmount() {
        var total = 0;
        for (var i = 0; i < this.$store.getters.tableData.length; i++) {
          total += this.$store.getters.tableData[i].salary;
        }
        return total;
      },
      filteredAmount() {
        var total = 0;
        for (var i = 0; i < this.filtered.length; i++) {
          total += this.filtered[i].salary;
        }
        return total;
      }
    }
  }
</script>



<style>
  tr:hover .el-button {
    display: inline-block;;
}
tr .el-button {
    display:none;
}
</style>